.clips__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
    height: 100%;
}

.clips__item {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.clips__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.clips__item p {
    margin-top: 1rem;
}

video {
    width: 100%;
    height: auto;
    border-radius: 1.5rem;
}

#clips h4 {
    margin: 1rem;
    margin-top: -2rem;
    margin-bottom: 1rem;
    text-align: center;
}

#clips h2 {
    margin-top: -4rem;
}

#clips {
    /* should take the whole screen heigh */
    height: 100%;

}

@media screen and (max-width: 2098px) {
    .clips__container {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1.2rem;
    }
}

/*------------- MEDIA QUERIES (MEDIUM DEVICES) -------------*/

@media screen and (max-width: 1490px) {
    .clips__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

/*------------- MEDIA QUERIES (SMALL DEVICES) -------------*/

@media screen and (max-width: 1032px) {
    .clips__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}