.involved__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.involved__item {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.involved__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.involved__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
}

.involved__item h3 {
    margin: 1.2rem 0 0rem;
    
}

.involved__item p {
    margin: 1.2rem 0 2rem;
}

.involved__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.slide-animation {
    animation: slide 0.5s ease;
    transform: translateX(-100%);
    transition: transform 0.5s ease;
  }
  
/*------------- MEDIA QUERIES (MEDIUM DEVICES) -------------*/

@media screen and (max-width: 1024px) {
   .involved__container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
   }
}

/*------------- MEDIA QUERIES (SMALL DEVICES) -------------*/

@media screen and (max-width: 600px) {
    .involved__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}