.misc__container {
    display: flex;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
}

.misc__item {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.misc__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.misc__item-games {
    /* grid with 2 colums */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    width: 19rem;
}

.misc__item-image {
    /* elements inside the grid properties */
    border-radius: 1.5rem;
    overflow: hidden;
    height: 11.1rem;
}

.misc__item h3 {
    margin: 1.2rem 0 0rem;
}

.misc__item p {
    margin: 1.2rem 0 2rem;
}

.misc__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.slide-animation {
    animation: slide 0.5s ease;
    transform: translateX(-100%);
    transition: transform 0.5s ease;
}

/*------------- MEDIA QUERIES (MEDIUM DEVICES) -------------*/

@media screen and (max-width: 800px) {
    .misc__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .misc__item-music {
        height: 5rem;
    }
}

/*------------- MEDIA QUERIES (SMALL DEVICES) -------------*/

@media screen and (max-width: 600px) {
    .misc__container {
        align-items: center;
    }
}