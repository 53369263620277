.notes__container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2.5rem;
}

.notes__item {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    /* size of the item */
    width: 22.5rem;
    margin-left: auto;
    margin-right: auto;
}

.notes__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.notes__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
}

.notes__item p {
    margin: 1.2rem 0 2rem;
}

.notes__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

#notes h5 {
    margin-top: 4rem;
    margin-bottom: -4rem;
}

#notes h4 {
    margin: 1rem;
    margin-top: -2rem;
    margin-bottom: 1rem;
    text-align: center;
}

#notes h2 {
    margin-top: -4rem;
}

@media screen and (max-width: 2098px) {
    .notes__container {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1.2rem;
    }
}

/*------------- MEDIA QUERIES (MEDIUM DEVICES) -------------*/

@media screen and (max-width: 1490px) {
    .notes__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

/*------------- MEDIA QUERIES (SMALL DEVICES) -------------*/

@media screen and (max-width: 1032px) {
    .notes__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}

/*------------- MEDIA QUERIES (REDUCE PDF PREVIEW SIZE IN SMALL DEVICES) -------------*/

@media screen and (max-width: 406px) {
    .notes__item {
        width: 17.5rem;
    }
}