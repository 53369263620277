header {
    height: 870px;
    padding-top: 4rem;
    overflow: hidden;
}

/*------------- code font -------------*/
.whoami {
    font-family: 'JetBrains Mono', monospace;
    font-size: 1.2rem;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/*------------- CTA -------------*/

.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/*------------- SOCIALS -------------*/

.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/*------------- PROFILE IMAGE -------------*/

.me {
    width: 30rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 15rem);
    margin-top: 4rem;
    border-radius: 99rem;
    overflow: hidden;
    box-shadow: 0 0 0 7px var(--color-primary);
    transition: all 0.3s;
}

.me:hover {
    box-shadow: 0 0 0 7px var(--color-secondary);
}

/*------------- SCROLL DOWN -------------*/

.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/*------------- MEDIA QUERIES (MEDIUM DEVICES) -------------*/

@media screen and (max-width: 1024px) {
    .me {
        width: 20rem;
        height: 20rem;
        left: calc(50% - 10rem);
    }
    
    header {
        height: 660px;
        padding-top: 2rem;
    }
}

/*------------- MEDIA QUERIES (SMALL DEVICES) -------------*/

@media screen and (max-width: 600px) {
    .me {
        width: 20rem;
        height: 20rem;
        left: calc(50% - 10rem);
    }

    header {
        height: 640px;
        padding-top: 2rem;
    }

    .header__socials, .scroll__down {
        display: none;
    }
}